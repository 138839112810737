import React, { useState } from "react";
import logo from "../imgs/LOGO_CARTÓRIO_PRETA-removebg-preview.png";
import { Menu } from "./Menu";
import { MenuMobile } from "./MenuMobile";
import { Link } from "react-router-dom";

import pqta2016 from "../imgs/premiacoes/2016.png";
import pqta2018 from "../imgs/premiacoes/2018.gif";
import pqta2023 from "../imgs/premiacoes/2023.png";
import pqta2024 from '../imgs/premiacoes/DIAMANTE2024 (1).png'

export const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const collapseMenu = () => {
    setMenu(!menu);
  };

  return (
    <nav className="navbar navbar-expand-lg w-100">
      <div className="top-topo">
        <span>
          <i className="bi bi-alarm-fill"></i> Atendimento: Segunda à sexta - 8h
          às 17h - Exceto feriados
        </span>
        <span>
          <i className="bi bi-envelope-fill"></i> E-mail:
          contato@cartorioritdsenadorcanedo.com.br
        </span>
        <span>
          <i className="bi bi-telephone-inbound-fill"></i> Telefones: (62)
          3010-2376 | (62) 3010-0876 |{" "}
          <Link to="https://wa.me/5562993468787" target="_blank">
            (62) 9 9346-8787 <i className="bi bi-whatsapp"></i>
          </Link>
        </span>
      </div>
      <div
        style={{ minWidth: "992px" }}
        className=" logo-top d-flex justify-content-between align-items-center"
      >
        <img src={logo} alt="Logo Cartório" />
        <div className="premiacoes">
          <img
            src={pqta2016}
            alt="Selo PQTA prata, de 2016"
            title="PQTA Prata 2016"
          />
          <img className="mx-3"
            src={pqta2018}
            alt="Selo PQTA outro, de 2018"
            title="PQTA Ouro 2018"
          />
          <img
            src={pqta2023}
            alt="Selo PQTA Diamante, de 2023"
            title="PQTA Diamante 2023"
          />
          <img
          src={pqta2024}
          className="mx-3"
          alt="Selo PQTA Diamante, de 20234"
          title="PQTA Diamante 2024"
        />
        </div>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={collapseMenu}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse w-100 justify-content-center"
        id="navbarSupportedContent"
      >
        <Menu />
      </div>
      {menu ? (
        <div className=" w-100 " id="">
          <MenuMobile
            collapseMenu={collapseMenu}
            menu={menu}
            setMenu={setMenu}
          />
        </div>
      ) : null}
    </nav>
  );
};
